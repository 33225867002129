export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGIN_FULFILLED = 'AUTH_LOGIN_FULFILLED'
export const AUTH_LOGIN_REJECTED = 'AUTH_LOGIN_REJECTED'

export const ITEM_SELECT = 'ITEM_SELECT'
export const ITEM_DESELECT = 'ITEM_DESELECT'

export const ITEM_NEW = 'ITEM_NEW'

export const ITEM_LIST = 'ITEM_LIST'
export const ITEM_LIST_FULFILLED = 'ITEM_LIST_FULFILLED'
export const ITEM_LIST_REJECTED = 'ITEM_LIST_REJECTED'

export const ITEM_LIST_FILTERS = 'ITEM_LIST_FILTERS'
export const ITEM_LIST_FILTERS_FULFILLED = 'ITEM_LIST_FILTERS_FULFILLED'
export const ITEM_LIST_FILTERS_REJECTED = 'ITEM_LIST_FILTERS_REJECTED'

export const ITEM_ADD = 'ITEM_ADD'
export const ITEM_ADD_FULFILLED = 'ITEM_ADD_FULFILLED'
export const ITEM_ADD_REJECTED = 'ITEM_ADD_REJECTED'

export const ITEM_UPDATE = 'ITEM_UPDATE'
export const ITEM_UPDATE_FULFILLED = 'ITEM_UPDATE_FULFILLED'
export const ITEM_UPDATE_REJECTED = 'ITEM_UPDATE_REJECTED'

export const ITEM_DELETE = 'ITEM_DELETE'
export const ITEM_DELETE_FULFILLED = 'ITEM_DELETE_FULFILLED'
export const ITEM_DELETE_REJECTED = 'ITEM_DELETE_REJECTED'

export const VALID_CENTER = 'VALID_CENTER'
export const VALID_CENTER_FULFILLED = 'VALID_CENTER_FULFILLED'
export const VALID_CENTER_REJECTED = 'VALID_CENTER_REJECTED'

export const VALID_PRODUCT = 'VALID_PRODUCT'
export const VALID_PRODUCT_FULFILLED = 'VALID_PRODUCT_FULFILLED'
export const VALID_PRODUCT_REJECTED = 'VALID_PRODUCT_REJECTED'

export const FILE_MODAL = 'FILE_MODAL'
export const FILE_UPLOAD = 'FILE_UPLOAD'
export const FILE_UPLOAD_PENDING = 'FILE_UPLOAD_PENDING'
export const FILE_UPLOAD_FULFILLED = 'FILE_UPLOAD_FULFILLED'
export const FILE_UPLOAD_REJECTED = 'FILE_UPLOAD_REJECTED'

export const FILE_DOWNLOAD = 'FILE_DOWNLOAD'
export const FILE_DOWNLOAD_PENDING = 'FILE_DOWNLOAD_PENDING'
export const FILE_DOWNLOAD_FULFILLED = 'FILE_DOWNLOAD_FULFILLED'
export const FILE_DOWNLOAD_REJECTED = 'FILE_DOWNLOAD_REJECTED'

export const INTERNAL_PRODUCT_LIST = 'INTERNAL_PRODUCT_LIST'
export const INTERNAL_PRODUCT_LIST_PENDING = 'INTERNAL_PRODUCT_LIST_PENDING'
export const INTERNAL_PRODUCT_LIST_FULFILLED = 'INTERNAL_PRODUCT_LIST_FULFILLED'
export const INTERNAL_PRODUCT_LIST_REJECTED = 'INTERNAL_PRODUCT_LIST_REJECTED'

export const EXTERNAL_PRODUCT_LIST = 'EXTERNAL_PRODUCT_LIST'
export const EXTERNAL_PRODUCT_LIST_PENDING = 'EXTERNAL_PRODUCT_LIST_PENDING'
export const EXTERNAL_PRODUCT_LIST_FULFILLED = 'EXTERNAL_PRODUCT_LIST_FULFILLED'
export const EXTERNAL_PRODUCT_LIST_REJECTED = 'EXTERNAL_PRODUCT_LIST_REJECTED'

export const INTERNAL_PRODUCT_SELECT = 'INTERNAL_PRODUCT_SELECT'
export const INTERNAL_PRODUCT_DESELECT = 'INTERNAL_PRODUCT_DESELECT'

export const EXTERNAL_PRODUCT_SELECT = 'EXTERNAL_PRODUCT_SELECT'
export const EXTERNAL_PRODUCT_DESELECT = 'EXTERNAL_PRODUCT_DESELECT'

export const RESET_PROCESS = 'RESET_PROCESS'
export const INIT = 'INIT'
export const NEXT_STEP = 'NEXT_STEP'
export const FIRST_SELECT = 'FIRST_SELECT'
export const SECOND_SELECT = 'SECOND_SELECT'
export const HOMOLOGATED_TRUE = 'HOMOLOGATED_TRUE'
export const HOMOLOGATED_FALSE = 'HOMOLOGATED_FALSE'
export const SELECT_HOMOLOGATED_INTERNAL_PRODUCT = 'SELECT_HOMOLOGATED_INTERNAL_PRODUCT'
export const SELECT_HOMOLOGATED_EXTERNAL_PRODUCT = 'SELECT_HOMOLOGATED_EXTERNAL_PRODUCT'

export const NEW_HOMOLOGATION = 'NEW_HOMOLOGATION'
export const NEW_HOMOLOGATION_PENDING = 'NEW_HOMOLOGATION_PENDING'
export const NEW_HOMOLOGATION_FULFILLED = 'NEW_HOMOLOGATION_FULFILLED'
export const NEW_HOMOLOGATION_REJECTED = 'NEW_HOMOLOGATION_REJECTED'

export const DELETE_HOMOLOGATION = 'DELETE_HOMOLOGATION'
export const DELETE_HOMOLOGATION_PENDING = 'DELETE_HOMOLOGATION_PENDING'
export const DELETE_HOMOLOGATION_FULFILLED = 'DELETE_HOMOLOGATION_FULFILLED'
export const DELETE_HOMOLOGATION_REJECTED = 'DELETE_HOMOLOGATION_REJECTED'

export const BLOCK_EXTERNAL_PRODUCT = 'BLOCK_EXTERNAL_PRODUCT'
export const BLOCK_EXTERNAL_PRODUCT_PENDING = 'BLOCK_EXTERNAL_PRODUCT_PENDING'
export const BLOCK_EXTERNAL_PRODUCT_FULFILLED = 'BLOCK_EXTERNAL_PRODUCT_FULFILLED'
export const BLOCK_EXTERNAL_PRODUCT_REJECTED = 'BLOCK_EXTERNAL_PRODUCT_REJECTED'

export const HOMOLOGATION_PRODUCT_LIST = 'HOMOLOGATION_PRODUCT_LIST'
export const HOMOLOGATION_PRODUCT_LIST_FULFILLED = 'HOMOLOGATION_PRODUCT_LIST_FULFILLED'
export const HOMOLOGATION_PRODUCT_LIST_REJECTED = 'HOMOLOGATION_PRODUCT_LIST_REJECTED'
/* 
export const USER_LIST = 'USER_LIST'
export const USER_LIST_FULFILLED = 'USER_LIST_FULFILLED'
export const USER_LIST_REJECTED = 'USER_LIST_REJECTED' */

export const USERS_FILTER_QUERY = 'USERS_FILTER_QUERY'

export const CHANGE_GRID_STATUS = 'CHANGE_GRID_STATUS'
export const CLEAN_GRID_CLICKS = 'CLEAN_GRID_CLICKS'
export const REMOVE_GRID = 'REMOVE_GRID'
export const REMOVE_ALL_GRIDS = 'REMOVE_ALL_GRIDS'
export const FETCH_FIELDS = 'FETCH_FIELDS'
export const SAVE_FILTER_QUERY = 'SAVE_FILTER_QUERY'
export const COPY_FILTER_QUERY = 'COPY_FILTER_QUERY'
export const DELETE_FILTER_QUERY = 'DELETE_FILTER_QUERY'
export const EDIT_WAS_CLICKED = 'EDIT_WAS_CLICKED'

export const ITEM_UPDATE_EMAIL = 'ITEM_UPDATE_EMAIL'
export const ITEM_DELETE_EMAIL = 'ITEM_DELETE_EMAIL'

export const ITEM_SELECT_OPEN_MODAL = 'ITEM_SELECT_OPEN_MODAL'
export const ITEM_DESELECT_CLOSE_MODAL = 'ITEM_DESELECT_CLOSE_MODAL'

export const SAVE_ATTRIBUTE_PRODUCT_QUERY = 'SAVE_ATTRIBUTE_PRODUCT_QUERY'

export const SAVE_ATTRIBUTE_PRICE_QUERY = 'SAVE_ATTRIBUTE_PRICE_QUERY'
export const SAVE_EXTERNAL_PRODUCT_ATTRIBUTE_PRICE_QUERY = 'SAVE_EXTERNAL_PRODUCT_ATTRIBUTE_PRICE_QUERY'

//------------------------------/ Dynamic Filter /-------------------------------------------//

export const FETCH_FIELDS_FULFILLED = 'FETCH_FIELDS_FULFILLED'

export const ADD_FILTER = 'ADD_FILTER'
export const REMOVE_FILTER = 'REMOVE_FILTER'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const CHANGE_FILTER_FIELD = 'CHANGE_FILTER_FIELD'
export const CHANGE_FILTER_OPERATOR = 'CHANGE_FILTER_OPERATOR'
export const CHANGE_FILTER_VALUE = 'CHANGE_FILTER_VALUE'

export const DAILY_SALES_QUERY = 'DAILY_SALES_QUERY'
export const DAILY_SALES_QUERY_FULFILLED = 'DAILY_SALES_QUERY_FULFILLED'
export const DAILY_SALES_QUERY_REJECTED = 'DAILY_SALES_QUERY_REJECTED'

export const FETCH_DIMENSION_OPTIONS = 'FETCH_DIMENSION_OPTIONS'
export const CLEAR_DAILY_DATEPICKER = 'CLEAR_DAILY_DATEPICKER'

export const CHANGE_EXPORTING_FORMAT = 'CHANGE_EXPORTING_FORMAT'

export const FETCH_PARAMS = 'FETCH_PARAMS'
export const CHANGE_MONTH_HORIZON_SELECTION = 'CHANGE_MONTH_HORIZON_SELECTION'

export const MAX_AVAILABLE_DATES = 'MAX_AVAILABLE_DATES'

export const EXECUTE_QUERY = 'EXECUTE_QUERY'

export const GET_APPLIED_CHANGES = 'GET_APPLIED_CHANGES'

// Tipos de campaña /--------------------------------------//

export const TASK_CENTERS_ID = 'TASK_CENTERS_ID'
export const TASK_LIST_UPDATE = 'TASK_LIST_UPDATE' //Tipo Campaña 2

export const SAVE_CAMPAIGN_TYPE_ONE = 'SAVE_CAMPAIGN_TYPE_ONE'
export const SAVE_CAMPAIGN_TYPE_TWO = 'SAVE_CAMPAIGN_TYPE_TWO'
export const SAVE_CAMPAIGN_TYPE_THREE = 'SAVE_CAMPAIGN_TYPE_THREE'
export const SAVE_CAMPAIGN_TYPE_FOUR = 'SAVE_CAMPAIGN_TYPE_FOUR'

export const GET_URL_DESC = 'GET_URL_DESC'

export const ITEM_SELECT_FULFILLED = 'ITEM_SELECT_FULFILLED'

export const CHANGE_CAMPAIGN_STATUS = 'CHANGE_CAMPAIGN_STATUS'

export const ITEM_LIST_CAMP_TASK_USER_TYPES = 'ITEM_LIST_CAMP_TASK_USER_TYPES'
export const ITEM_LIST_CAMP_TASK_USER_TYPES_FULFILLED = 'ITEM_LIST_CAMP_TASK_USER_TYPES_FULFILLED'