import axios from 'axios';
import store from './store';
//import runtimeEnv from '@mars/heroku-js-runtime-env';
const env = process.env

// https://github.com/axios/axios/issues/164#issuecomment-327837467z
axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  var config = err.config;
  // If config does not exist or the retry option is not set, reject
  if (!config || !config.retry) return Promise.reject(err);

  // Set the variable for keeping track of the retry count
  config.__retryCount = config.__retryCount || 0;

  // Check if we've maxed out the total number of retries
  if (config.__retryCount >= config.retry) {
    // Reject with the error
    return Promise.reject(err);
  }

  // Increase the retry count
  config.__retryCount += 1;

  // Create new promise to handle exponential backoff
  var backoff = new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, config.retryDelay || 1);
  });

  // Return the promise in which recalls axios to retry the request
  return backoff.then(function () {
    return axios(config);
  });
});

// usage: axios.get("API_PATH", { retry: 5, retryDelay: 3000 })

const REACT_APP_BACKEND_URL = env.REACT_APP_BACKEND_URL

export const get = (url) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token}`;
  return axios.get(REACT_APP_BACKEND_URL + url, { retry: 3, retryDelay: 2000 });
}

export function post(url,body) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token}`;
  return axios.post(REACT_APP_BACKEND_URL + url,body);
}

export const put = (url, body) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token}`;
  return axios.put(REACT_APP_BACKEND_URL + url, body);
}

export function del(url, data) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token}`;
  return axios.delete(REACT_APP_BACKEND_URL + url,{data});
}
