import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form'

import loginReducer from './authReducer'
import statusReducer from './appStatusReducer'

const rootReducer = combineReducers({
  userProfile: loginReducer,
  appStatus: statusReducer,
  form: formReducer,
});

export default rootReducer;
