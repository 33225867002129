//import runtimeEnv from '@mars/heroku-js-runtime-env';
const env = process.env
export default function(state = [], action) {
  //console.log(action)
  if (env.REACT_APP_DEV || env.REACT_APP_LOCALHOST) {
    //console.log("ACTION: ", action)
    //console.log("\n");
  }
    if (action.type.substr(action.type.length - 7) === 'PENDING')
      return {loading:true}
    else if (action.type.substr(action.type.length - 9) === 'FULFILLED')
      return {loading:false}
    else if (action.type.substr(action.type.length - 8) === 'REJECTED')
      return {loading:false}
    else
      return state
}