import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loadable from 'react-loadable'
import { Route, Switch, withRouter } from 'react-router-dom'
import ReactLoading from 'react-loading'

import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'

//import runtimeEnv from '@mars/heroku-js-runtime-env';

import { authLogin } from './actions/authActions'

import { library } from '@fortawesome/fontawesome-svg-core'

import packageJson from '../package.json'

import ReactGA from 'react-ga';

import {
  faUpload, faPlus, faToggleOn, faToggleOff, faDownload, faShoppingBasket, faTabletAlt,
  faPlusSquare, faMinusSquare, faBuilding, faClipboardList, faFileAlt, faCheck,
  faExclamationTriangle, faTrashAlt, faUndoAlt, faSave, faSpinner, faTimes, faMobileAlt,
  faLock, faUnlock, faCheckDouble, faFilter, faSearch, faInfoCircle, faListAlt, faUndo, faMinus,
  faAngleDoubleLeft, faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faUpload, faPlus, faToggleOn, faToggleOff, faDownload, faShoppingBasket, faTabletAlt,
  faPlusSquare, faMinusSquare, faBuilding, faClipboardList, faFileAlt, faCheck,
  faExclamationTriangle, faTrashAlt, faUndoAlt, faSave, faSpinner, faTimes, faMobileAlt,
  faLock, faUnlock, faCheckDouble, faFilter, faSearch, faInfoCircle, faListAlt, faUndo, faMinus,
  faAngleDoubleLeft, faAngleDoubleRight
)

const env = process.env

//require('dotenv').config()

// Loadables //====================================================================================//

const IniHome = Loadable({
  loader: () => import(/* webpackChunkName: '_ch_home' */ './pages/home'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

class App extends Component {
  constructor() {
    super()
    this.state = {
      version: packageJson.version || 'ERROR', //'2020.03.27-1'
    }

    // ReactGa //------------------------------------------------------------------------------//

    ReactGA.initialize('UA-52975906-1');
    // To Report Page View 
    ReactGA.pageview(window.location.pathname + window.location.search);

  }

  componentDidMount() {
    console.log('Versión: ' + this.state.version)
  }

  componentWillMount() {
    try {
      const location_hash = this.props ? (this.props.location ? this.props.location.hash : null) : null
      const loginToken = /*env.REACT_APP_TOKEN_OVERRIDE*/ location_hash ? (location_hash.includes("=") ? location_hash.split('=')[1].split('&')[0] : null) : null

      if (loginToken) {
        this.props.authLogin(loginToken)
          .then()
          .catch(err => { console.log("error de login", err) })
      }
    } catch (e) {
      console.log("ERROR EN APP authLogin:", e)
      //window.location = env.REACT_APP_REDIRECT_URL
    }
  }

  reload = () => {
    window.location.reload();
  }

  // Rutas //===================================================================================//

  render() {

    return (
      <div className='App'>
        <main>
          <Switch>
            <Route path='/'>
              <div>
                <div>
                  <Route exact path='/' component={IniHome} />
                </div>
              </div>
            </Route>
          </Switch>
        </main>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userProfile: state.userProfile,
    isAuthenticated: state.userProfile.isAuthenticated,
    message: state.userProfile.message,
  }
}

const mapDispatchToProps = {
  authLogin,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
