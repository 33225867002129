import * as api from './../api_service'
import * as types from './actionTypes'

export function authLogin(token) {
  const request = api.get('/login?id=' + token);
  return {
    type: types.AUTH_LOGIN,
    payload: request
  };
}
